import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import Layout from '../components/Layout';
import CtaBar from '../components/CtaBar';
import ArrowLink from '../components/ArrowLink';

//import Container from 'react-bulma-components/lib/components/container';
//import Columns from 'react-bulma-components/lib/components/columns';
import mdToHtml from '../util/mdToHtml';
import common, { PageWrap } from '../styles/common';

export const EngagementsDetailTemplate = ({
	name,
	trustees,
	industry,
	isActive,
	content,
	documents,
	documentsContent,
	team,
}) => {
	return (
		<PageWrap>
			<div className="container" style={{ padding: '0 2rem' }}>
				<h3 style={{ margin: '2rem 0 0 0' }}>Engagement:</h3>
				<h2 style={{ margin: 0 }}>{name}</h2>
				<h4 style={{ margin: 0 }}>{industry}</h4>
				<div dangerouslySetInnerHTML={{ __html: mdToHtml(content) }} />
			</div>
			<Docs>
				<div className="container" style={{ padding: '0 2rem' }}>
					<div className="columns">
						<div className="column">
							{documentsContent && (
								<div
									dangerouslySetInnerHTML={{
										__html: mdToHtml(documentsContent),
									}}
								/>
							)}
							{documents && (
								<>
									{documents.notices_of_application_motion &&
										documents.notices_of_application_motion.length !== 0 &&
										documents.notices_of_application_motion[0].name !==
											null && (
											<>
												<h3>Notices of Application / Motion</h3>
												<ul>
													{documents.notices_of_application_motion.map(
														(doc, i) => (
															<li key={i}>
																<a
																	target="_blank"
																	rel="noopener noreferrer"
																	href={doc.file}
																	key={i}
																>
																	{doc.name}
																</a>
															</li>
														)
													)}
												</ul>
											</>
										)}
									{documents.endorsements &&
										documents.endorsements.length !== 0 &&
										documents.endorsements[0].name !== null && (
											<>
												<h3>Endorsements</h3>
												<ul>
													{documents.endorsements.map((doc, i) => (
														<li key={i}>
															<a
																target="_blank"
																rel="noopener noreferrer"
																href={doc.file}
															>
																{doc.name}
															</a>
														</li>
													))}
												</ul>
											</>
										)}
									{documents.court_orders &&
										documents.court_orders.length !== 0 &&
										documents.court_orders[0].name !== null && (
											<>
												<h3>Court Orders</h3>
												<ul>
													{documents.court_orders.map((doc, i) => (
														<li key={i}>
															<a
																target="_blank"
																rel="noopener noreferrer"
																href={doc.file}
															>
																{doc.name}
															</a>
														</li>
													))}
												</ul>
											</>
										)}
									{documents.current_service_list_and_address_list &&
										documents.current_service_list_and_address_list.length !==
											0 &&
										documents.current_service_list_and_address_list[0].name !==
											null && (
											<>
												<h3>Current eServicesList & Address List</h3>
												<ul>
													{documents.current_service_list_and_address_list.map(
														(doc, i) => (
															<li key={i}>
																<a
																	target="_blank"
																	rel="noopener noreferrer"
																	href={doc.file}
																>
																	{doc.name}
																</a>
															</li>
														)
													)}
												</ul>
											</>
										)}
									{documents.affidavits &&
										documents.affidavits.length !== 0 &&
										documents.affidavits[0].name !== null && (
											<>
												<h3>Affidavits</h3>
												<ul>
													{documents.affidavits.map((doc, i) => (
														<li key={i}>
															<a
																target="_blank"
																rel="noopener noreferrer"
																href={doc.file}
															>
																{doc.name}
															</a>
														</li>
													))}
												</ul>
											</>
										)}
									{documents.responding_affidavits &&
										documents.responding_affidavits.length !== 0 &&
										documents.responding_affidavits[0].name !== null && (
											<>
												<h3>Responding Affidavits</h3>
												<ul>
													{documents.responding_affidavits.map((doc, i) => (
														<li key={i}>
															<a
																target="_blank"
																rel="noopener noreferrer"
																href={doc.file}
															>
																{doc.name}
															</a>
														</li>
													))}
												</ul>
											</>
										)}
								</>
							)}
						</div>
						<div className="column">
							{documents && (
								<>
									{documents.facta &&
										documents.facta.length !== 0 &&
										documents.facta[0].name !== null && (
											<>
												<h3>Facta</h3>
												<ul>
													{documents.facta.map((doc, i) => (
														<li key={i}>
															<a
																target="_blank"
																rel="noopener noreferrer"
																href={doc.file}
															>
																{doc.name}
															</a>
														</li>
													))}
												</ul>
											</>
										)}
									{documents.books_of_authority &&
										documents.books_of_authority.length !== 0 &&
										documents.books_of_authority[0].name !== null && (
											<>
												<h3>Books of Authority</h3>
												<ul>
													{documents.books_of_authority.map((doc, i) => (
														<li key={i}>
															<a
																target="_blank"
																rel="noopener noreferrer"
																href={doc.file}
															>
																{doc.name}
															</a>
														</li>
													))}
												</ul>
											</>
										)}
									{documents.court_reports &&
										documents.court_reports.length !== 0 &&
										documents.court_reports[0].name !== null && (
											<>
												<h3>Court Reports</h3>
												<ul>
													{documents.court_reports.map((doc, i) => (
														<li key={i}>
															<a
																target="_blank"
																rel="noopener noreferrer"
																href={doc.file}
															>
																{doc.name}
															</a>
														</li>
													))}
												</ul>
											</>
										)}
									{documents.press_releases &&
									documents.press_releases.length !== 0 &&
									documents.press_releases[0] &&
									documents.press_releases[0].name !== null ? (
										<>
											<h3>Press Releases</h3>
											<ul>
												{documents.press_releases.map((doc, i) => {
													const url = doc.file
														? doc.file
														: doc.link
														? doc.link
														: '#';

													return (
														<li key={i}>
															<a
																target="_blank"
																rel="noopener noreferrer"
																href={url}
															>
																{doc.name}
															</a>
														</li>
													);
												})}
											</ul>
										</>
									) : null}
									{documents.sale_process &&
									documents.sale_process.length !== 0 &&
									documents.sale_process[0] &&
									documents.sale_process[0].name !== null ? (
										<>
											<h3>Sale Process</h3>
											<ul>
												{documents.sale_process.map((doc, i) => (
													<li key={i}>
														<a
															target="_blank"
															rel="noopener noreferrer"
															href={doc.file}
														>
															{doc.name}
														</a>
													</li>
												))}
											</ul>
										</>
									) : null}
									{documents.other_relevant_documents &&
										documents.other_relevant_documents.length !== 0 &&
										documents.other_relevant_documents[0].name !== null && (
											<>
												<h3>Other Relevant Documents</h3>
												<ul>
													{documents.other_relevant_documents.map((doc, i) => (
														<li key={i}>
															<a
																target="_blank"
																rel="noopener noreferrer"
																href={doc.file}
															>
																{doc.name}
															</a>
														</li>
													))}
												</ul>
											</>
										)}
								</>
							)}
							{trustees && trustees.length ? (
								<>
									<h3>Trustee In Charge</h3>
									{team
										.filter(({ node }) => {
											return trustees.some(
												(item) => item.trustee === node.name
											);
										})
										.map(({ node }, index) => (
											<TrusteeTile key={index}>
												<ImageWrap>
													<img src={node.round_image} alt="" />
												</ImageWrap>
												<ContentWrap>
													<h3>{node.name}</h3>
													<h4>{node.title}</h4>
													<ArrowLink href={node.fields.slug}>Contact</ArrowLink>
												</ContentWrap>
											</TrusteeTile>
										))}
								</>
							) : null}
						</div>
					</div>
					<div style={{ textAlign: 'center' }}>
						<a className="link" href="/engagements">
							Back to Engagements
						</a>
					</div>
				</div>
			</Docs>
			<CtaBar>
				<h3>
					Let's start a conversation.
					<br />
					<a href="/contact">Contact us today.</a>
				</h3>
			</CtaBar>
		</PageWrap>
	);
};

const EngagementDetail = ({ data }) => {
	const {
		name,
		trustees,
		industry,
		is_active,
		content,
		documents,
		documents_content,
	} = data.engagementsJson;
	const team = data.allTeamJson.edges;

	return (
		<Layout>
			<Helmet>
				<title>{name} | Spergel Corporate</title>
				<style>{`div#chat-widget-container {display: none;}`}</style>
			</Helmet>
			<EngagementsDetailTemplate
				name={name}
				trustees={trustees}
				industry={industry}
				isActive={is_active}
				content={content}
				documents={documents}
				documentsContent={documents_content}
				team={team}
			/>
		</Layout>
	);
};

export default EngagementDetail;

export const engagementDetailQuery = graphql`
	query Engagement($id: String!) {
		engagementsJson(id: { eq: $id }) {
			id
			fields {
				slug
			}
			name
			trustees {
				trustee
			}
			industry
			is_active
			content
			documents_content
			documents {
				sale_process {
					name
					file
				}
				press_releases {
					name
					file
					link
				}
				current_service_list_and_address_list {
					name
					file
				}
				other_relevant_documents {
					name
					file
				}
				endorsements {
					name
					file
				}
				notices_of_application_motion {
					name
					file
				}
				books_of_authority {
					name
					file
				}
				facta {
					name
					file
				}
				affidavits {
					name
					file
				}
				court_orders {
					name
					file
				}
				responding_affidavits {
					name
					file
				}
				court_reports {
					name
					file
				}
			}
		}
		allTeamJson {
			edges {
				node {
					round_image
					name
					title
					fields {
						slug
					}
				}
			}
		}
	}
`;

const Docs = styled.div`
	padding: 4rem 0;
	margin-top: 4rem;
	background-color: #f2f2f2;
	a {
		color: inherit;
	}
	a.link {
		color: ${common.spergelRed};
		font-size: 1.5rem;
		font-weight: 700;
		text-decoration: none;
	}
	h2,
	h3 {
		font-size: 1.25rem;
	}
`;

const TrusteeTile = styled.div`
	display: flex;
	margin: 1rem;
`;

const ImageWrap = styled.div`
	flex-basis: 160px;
	img {
		width: 100%;
	}
`;

const ContentWrap = styled.div`
	flex-basis: 200px;
	flex-grow: 1;
	padding: 1rem 0 1rem 2rem;
	a {
		margin-left: 0;
		font-size: 1rem;
	}
	h3 {
		@media only screen and (min-width: 768px) {
			font-size: 2rem;
		}
		font-size: 1.25rem;
		margin: 0;
		font-weight: 300;
	}
	h4 {
		margin: 0;
		font-weight: 300;
		margin-bottom: 1rem;
	}
`;
